#sections {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 2;
  @include media("<=1024px") {
    text-shadow: 0 3px 14px rgba(0, 0, 0, 0.5);
    text-align: center;
    transform: translate(-50%, -50%);
    left: 50%;
  }
  .section {
    font-family: "Montserrat", sans-serif;
    position: relative;

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    left: 6rem;
    width: 35rem;

    .title {
      font-size: 6rem;
      font-family: "Yeseva One", serif;
      margin: 0.2rem 0;
    }
    .description {
      font-size: 1.2rem;
      line-height: 2rem;
    }
    .button {
      text-transform: uppercase;
      font-weight: bold;
      word-spacing: 0.3rem;
      display: inline-flex;
      align-items: center;
      .arrow {
        // animation: animate 1s infinite alternate ease-in;
        display: block;
        position: relative;
        margin-left: 1rem;
        width: 1.5rem;
        height: 0.2rem;
        left: 0;
        background: $black;
        .top,
        .bottom {
          position: absolute;
          display: block;
          background: $black;
          width: 0.6rem;
          height: 0.2rem;
          right: 0;
        }
        .top {
          transform: rotate(35deg);
          transform-origin: top right;
        }
        .bottom {
          transform: rotate(-35deg);
          transform-origin: bottom right;
        }
      }
    }
    @include media("<=desktop") {
      .title {
        font-size: 5rem;
      }
    }
    @include media("<=tablet") {
      .title {
        font-size: 4rem;
      }
    }
    @include media("<=1024px") {
      left: 0;
      width: 100%;
      align-items: center;
      .title {
        font-size: 3rem;
      }
      .description {
        font-size: 1rem;
        text-align: center;
      }
    }

    @include media(">=LGdesktop") {
      .title {
        font-size: 8rem;
      }
    }
  }
}

@keyframes animate {
  0% {
    left: 0;
  }

  100% {
    left: 1rem;
  }
}
