#App {
  display: flex;
  justify-content: center;

  .scroll-layer {
    position: fixed;
    transform-style: preserve-3d;
    transform: translateX(-15vw) translateY(275px) rotateX(45deg) rotateZ(45deg);
    width: 70vw;
    max-width: 1200px;
    height: calc(100vh - 280px);
    .photo-grid {
      display: grid;
      position: fixed;
      transform-style: preserve-3d;
      grid-template-columns: repeat(3, 20rem);
      column-gap: 5rem;
      row-gap: 5rem;
      justify-content: center;

      .card {
        position: relative;
        width: 20rem;
        height: 20rem;
        &.hovered {
          :nth-child(1) {
            transform: translate3d(0, 0, 2px) rotate3d(0, 0, 1, 3deg);
            transition: all 0.3s ease 0.4;
          }
          :nth-child(2) {
            transform: translate3d(0, 0, 49px) rotate3d(0, 0, 1, -8deg);
            transition: all 0.3s ease 0.2;
          }
          :nth-child(3) {
            transform: translate3d(0, 0, 150px) rotate3d(0, 0, 1, 8deg);
            transition: all 0.3s ease;
          }
        }
        img {
          transition: all 0.3s ease;
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
