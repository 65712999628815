#mobile-section {
  height: 100vh;
  width: 100vw;
  display: flex;
  font-family: "Bodoni Moda", serif;
  background-color: $black;
  align-items: center;
  justify-content: center;
  // font-family: ;
  span {
    font-size: 1rem;
    line-height: 250%;
    width: 60%;
    text-align: center;
    color: $white;
  }
  footer {
    position: absolute;
    font-size: 0.8rem;
    bottom: 3rem;
    color: $white;
  }
}
