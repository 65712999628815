#cursor {
  .main-cursor,
  .secondary-cursor {
    transition: 0.3s;
    transition-property: width, height;
    transform-origin: center;
  }
  .main-cursor {
    mix-blend-mode: difference;
    z-index: 9999999;
    pointer-events: none;
    position: absolute;
    display: block;
    height: 0.6rem;
    width: 0.6rem;
    border-radius: 100%;
    background: $white;
  }
  .secondary-cursor {
    mix-blend-mode: difference;
    position: absolute;
    pointer-events: none;
    z-index: 999999;
    display: block;
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    background: transparent;
    // background: $white;
    border: 1px solid $white;
  }
  &.hovered {
    .main-cursor {
      width: 2rem;
      height: 2rem;
    }
    .secondary-cursor {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  &.back {
    .main-cursor {
      width: 0;
      height: 0;
    }
    .secondary-cursor {
      width: 3.5rem;
      height: 3.5rem;
    }
  }

  &.locked {
    .main-cursor {
      height: 0rem;
      width: 0rem;
    }
    .secondary-cursor {
      // background: $white;

      // width: 80px;
      // height: 80px;
    }
  }
}
