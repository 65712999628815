.category-page {
  background-color: #fff;
  // z-index: 8;
  position: fixed;
  width: 100%;
  height: 100%;

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .back-btn {
    position: absolute;
    top: 8vh;
    left: 50%;
    transform: translateX(-50%);
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-weight: bolder;
    mix-blend-mode: difference;
    z-index: 2;
    font-size: 2.5rem;
    -webkit-text-stroke: 1px $white;
    -webkit-text-fill-color: transparent;
    color: $white;

    span {
      display: block;
      position: relative;
      &:before {
        content: "BACK";

        position: absolute;

        top: 0;
        left: 0;
        height: 0;
        display: block;
        width: 100%;
        color: $white;
        -webkit-text-fill-color: #fff;
        overflow: hidden;

        transition: 0.5s ease;
      }
      &:hover:before {
        height: 100%;
      }
    }
  }

  .category-img {
    position: relative;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .description {
      position: relative;
      mix-blend-mode: difference;
      font-family: monospace;
      text-transform: lowercase;
      color: $white;
      top: -0.2rem;
      margin-left: 1rem;
    }
  }
  .pictures-grid {
    margin-top: 5rem;
    padding-bottom: 5rem;
    display: grid;
    grid-template-columns: repeat(12, 7%);
    grid-column-gap: 1%;
    align-items: center;
    justify-content: center;
    @include media("<=tablet") {
      grid-template-columns: repeat(12, 8.333333%);
      grid-column-gap: initial;
    }
    .grid-section {
      height: 100%;
      position: relative;

      &.grid-col-start-auto {
        grid-column-start: auto;
      }

      @for $i from 1 through 8 {
        &.grid-col-start-#{$i} {
          grid-column-start: $i;
        }
      }
      @for $i from 1 through 12 {
        &.grid-col-end-#{$i} {
          grid-column-end: span $i;
        }
      }

      @include media("<=tablet") {
        &.grid-col-end-2 {
          grid-column-end: span 6;
        }
        &.grid-col-end-3 {
          grid-column: auto / span 6;
        }
        &.grid-col-end-4 {
          grid-column: auto / span 6;
        }
        &.grid-col-end-5 {
          grid-column: auto / span 6;
        }
        &.grid-col-end-6 {
          grid-column: auto / span 12;
        }
        &.grid-col-end-8 {
          grid-column: auto / span 12;
        }
      }

      .magnet-area {
        z-index: 9;
        position: absolute;
        left: 20%;
        top: 20%;
        width: 60%;
        height: 60%;

        &.full {
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
      }

      .picture {
        overflow: hidden;
        padding: 2rem 2rem;
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        height: 100%;
        img {
          box-shadow: 0px 5px 18px 2px rgba(0, 0, 0, 0.15);
          width: 100%;
          height: 100%;
          object-fit: cover;
          clip-path: polygon(0 -100%, 0% 100%, 200% 100%);
          &.animate {
            animation: polygonAnimation 1s forwards;
          }
        }
        .text {
          width: 100%;
          height: 100%;

          span {
            width: 100%;
          }
          &.description {
            display: flex;
            // flex-direction: column; //not sure
            justify-content: center;
            align-items: center;
            white-space: pre-line;
          }
          &.noMargin {
            p {
              margin: 0;
            }
          }
          &.big {
            font-size: 5rem;
            white-space: pre;
            text-align: center;
            justify-content: center;
            display: flex;
            align-items: flex-end;
            @include media("<=tablet") {
              font-size: 2rem;
            }
          }
        }
        .location {
          width: 100%;
          // font-family: "Bodoni Moda", serif;
          font-family: monospace;
          position: absolute;
          bottom: 2rem;
          left: 1.8rem;
          transform: rotate(-90deg);
          transform-origin: left bottom;
          transition: color 0.6s ease;
          text-transform: lowercase;
        }
        @include media("<=tablet") {
          // padding: 0.5rem 0.5rem;
          padding: 0;

          .location {
            display: none;
          }
        }
      }
    }
  }
}

@keyframes polygonAnimation {
  from {
    clip-path: polygon(0 -100%, 0% 100%, 200% 100%);
  }
  to {
    clip-path: polygon(0% 100%, 0% 100%, 0% 100%);
  }
}
