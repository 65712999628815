#about {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: $black;

  .back {
    position: absolute;

    left: 6rem;
    top: 2rem;
    z-index: 9;
    font-family: "Montserrat", sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
    a {
      cursor: none !important;
      color: $white;
      text-decoration: none;
    }
  }

  .scroll {
    position: absolute;
    left: 50%;
    bottom: 5vh;
    font-family: Helvetica Neue, Helvetica, Arial;
    transform: translateX(-50%);
    color: $white;
    z-index: 9;
  }

  .canvas-scene {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  h1 {
    font-size: 4rem;
    color: $grey;
  }
}
