@mixin position-center($text-align: center) {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: $text-align;
}

#gallery-loader {
  pointer-events: none;
  font-size: 2rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //   background: #000;
  z-index: 11;

  .loading-text {
    @include position-center;
    width: 100%;
    height: 100px;
    line-height: 100px;
    span {
      display: inline-block;
      margin: 0 5px;
      color: #fff;
      text-shadow: 0 2px 8px rgba(0, 0, 0, 0.715);
      font-family: "Montserrat", sans-serif;
      @for $i from 0 through 6 {
        &:nth-child(#{$i + 1}) {
          filter: blur(0px);
          animation: blur-text 1.5s #{$i / 5}s infinite linear alternate;
        }
      }
    }
  }
}

@keyframes blur-text {
  0% {
    filter: blur(0px);
  }
  100% {
    filter: blur(3px);
  }
}
