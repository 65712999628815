#scroll-down {
  position: absolute;
  display: block;
  bottom: 1rem;
  mix-blend-mode: difference;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 79px;
  text-align: center;
}
.arrow-down {
  display: block;
  margin: 0 auto;
  width: 10px;
  height: 38px;
}
.arrow-down:after {
  content: "";
  display: block;
  margin: 2px 1px;
  padding: 0;
  width: 8px;
  height: 8px;
  border-top: 2px solid $white;
  border-right: 2px solid $white;
  transform: rotate(135deg);
}
#scroll-title {
  display: block;
  text-transform: uppercase;
  color: $white;
  font-family: Helvetica Neue, Helvetica, Arial;
  // font-family: monospace;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.1em;
}
#scroll-down::before {
  animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
  /* IE 10+, Fx 29+ */

  position: absolute;
  top: 0px;
  left: 50%;
  margin-left: -1px;
  width: 2px;
  height: 90px;
  background: $white;
  content: " ";
}

@keyframes elasticus {
  0% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
  50% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50.1% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
}
