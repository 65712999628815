#home {
  width: 100vw;
  min-height: 100vh;
  position: absolute;

  .about {
    position: absolute;

    left: 6rem;
    bottom: 2rem;
    z-index: 4;
    font-family: "Montserrat", sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
    @include media("<=mobileL") {
      left: 50%;
      transform: translateX(-50%);
      bottom: 4rem;
    }
    a {
      cursor: none !important;
      text-decoration: none;
    }
  }

  .canvas-scene {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .background {
    background: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: 1s ease-in-out;
  }

  .category-selector {
    font-family: "Bodoni Moda", serif;
    z-index: 2;
    position: absolute;
    top: 50%;
    right: 0;
    padding-right: 6%;
    transform: translateY(-50%);

    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .category {
        position: relative;
        display: inline-flex;
        padding: 0.2rem 1rem;

        .category-name {
          text-transform: lowercase;
          margin-right: 1rem;
        }
        .selector {
          width: 0.6rem;
          height: 1.2rem;
          background-color: $white;
          border-radius: 0.5rem;
          &.selected {
            background-color: lightblue;
          }
        }
      }
    }
  }
}
