*,
*:before,
*:after {
  // margin: 0; //for tests
  // padding: 0; //for tests
  box-sizing: border-box;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body,
html {
  cursor: none;
  margin: 0;
  position: relative;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  min-width: 100vw;
  overflow-y: hidden;
  overflow-x: hidden;
  user-select: none;
  background: transparent;
  color: $black;
  // margin: 0; //for tests
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  width: 100%;
}

h1 {
  transition: color 0.3s ease;
}

img {
  max-width: 100vw;
  // max-height: 100vh;
}

.text-align-left {
  text-align: left;
}
.text-align-center {
  text-align: center;
}
.text-align-right {
  text-align: right;
}

@for $i from 0 through 4 {
  .line-height-#{$i} {
    line-height: $i + rem;
  }
}

@for $i from 1 through 5 {
  .font-size-#{$i} {
    font-size: 0.5 * $i + rem;
  }
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.italic {
  font-style: italic;
}
