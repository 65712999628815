#splashscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: transparent;
  color: $white;
  z-index: 12;
  font-family: "Bodoni Moda", serif;
  //   font-family: "Montserrat";
  overflow: hidden;
  // animation: splashscreen 2s cubic-bezier(0.75, 0.01, 0.3, 0.99) forwards 4.5s;

  .container {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    .name {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 9;
      top: 50vh;
      left: 50vw;
      display: flex;
      align-items: center;
      justify-content: center;
      mix-blend-mode: difference;
      font-size: 1.2rem;
      transform: translate(-50%, -50%);
      text-transform: uppercase;
      p {
        animation: slant 0.2s ease forwards 1.3s;
      }
    }
    .dash {
      position: absolute;
      mix-blend-mode: difference;
      display: block;
      background: $white;
      width: 15rem;
      height: 0.1rem;
      top: 50vh;
      left: 50vw;
      transform: translate(-50%, -50%) scaleX(0);
      transform-origin: left;
      animation: dash 0.2s ease-in 1s forwards;
    }

    .backgrounds {
      width: 100%;
      :nth-child(1) {
        transform: translate(-50%, -50%) rotate(0deg) scale(1);
        transform-origin: top;
        top: 50% !important;
        left: 50% !important;
        width: 250vw !important;
        height: 250vh !important;
      }
      :nth-child(2) {
        z-index: -1;
      }
      :nth-child(3) {
        z-index: -2;
      }
      :nth-child(4) {
        z-index: -3;
      }

      .black-bg {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $black;
        width: 100vw;
        height: 100vh;
        &:nth-child(1) {
          // animation: black-bg 3s cubic-bezier(0.22, 0.4, 0, 0.95) forwards 2s;
        }
      }
      .white-bg {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $white;
        width: 100vw;
        height: 100vh;
      }
    }
  }
  .mobile-prevention {
    font-family: "Montserrat", sans-serif;
    font-size: 0.6rem;
    display: inline-flex;
    width: 100%;
    justify-content: center;
    position: fixed;
    mix-blend-mode: difference;
    bottom: 2rem;
    left: 0;
    color: $white;
  }
}

@keyframes dash {
  from {
    transform: translate(-50%, -50%) scaleX(0);
  }
  to {
    transform: translate(-50%, -50%) scaleX(1);
  }
}

@keyframes slant {
  from {
    transform: skewX(0deg);
  }
  to {
    transform: skewX(-35deg);
  }
}

// @keyframes black-bg {
//   from {
//     width: 100%;
//   }
//   to {
//     width: 50%;
//   }
// }

@keyframes black-bg {
  0% {
    width: 100%;
  }
  50% {
    width: 50%;
  }
  65% {
    width: 50%;
  }

  100% {
    width: 55%;
  }
}

@keyframes splashscreen {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}
